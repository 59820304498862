import React, { useCallback, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Handyman, Home } from '@mui/icons-material'
import gfm from 'remark-gfm'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { Grid, Typography } from '@mui/material'

import { routePaths } from '../constants/routes'
import { CustomMarkdown, SubContainer } from '../components/common/Styled'
import SEO from '../components/common/seo'
import Header from '../components/common/Header'
import { buildImageLink } from '../helpers'

const query = graphql`
  query {
    home: strapiHome {
      referencing {
        metaTitle
      }
    }
    renovation: strapiRenovation {
      description {
        data {
          description
        }
      }
      images {
        url
        alternativeText
        width
        height
      }
      referencing {
        metaTitle
      }
    }
  }
`

const RenovationPage = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const { renovation, home } = useStaticQuery(query)

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setViewerIsOpen(false)
    setCurrentImage(0)
  }

  const photos = renovation?.images
    .map(image => {
      return {
        src: buildImageLink(image.url),
        width: image.width,
        height: image.height
      }
    })
    .reverse()

  const title = renovation.referencing.metaTitle

  const items = [
    {
      icon: <Home />,
      label: home.referencing.metaTitle,
      route: routePaths.home
    },
    {
      icon: <Handyman />,
      label: title
    }
  ]

  return (
    <SubContainer>
      <SEO title={title} />
      <Header {...{ items, title }} />
      <Grid container justifyContent="center">
        <Typography width="100%" maxWidth={800} variant="h6" component="div">
          <CustomMarkdown
            remarkPlugins={[gfm]}
            transformImageUri={buildImageLink}
          >
            {renovation.description.data.description}
          </CustomMarkdown>
        </Typography>
      </Grid>
      <Grid
        mt={2}
        mx="auto"
        sx={{
          width: '80%'
        }}
      >
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </Grid>
    </SubContainer>
  )
}
export default RenovationPage
